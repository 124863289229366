import React from "react";
import { MultiDayItem } from "./MultiDayItem";
import { MultiDayItineraryItem } from "../../../pages/ProductPage/interfaces";
import { Divider } from "../../Divider";
import classes from "./MultiDay.module.scss";

type MultiDayProps = {
  siteSettings: any;
  productData: any;
};

export const MultiDay: React.FC<MultiDayProps> = ({
  siteSettings,
  productData,
}: MultiDayProps) => {
  return (
    <div className="multi__container">
      <p className={classes.multiTitle}>
        {/* {siteSettings.productSettings.whatToExpectTitle} */}
      </p>
      {productData?.itinerary?.map(
        (item: MultiDayItineraryItem, index: number) => {
          return (
            <MultiDayItem
              key={index}
              day={item}
              dictionary={siteSettings.dictionary}
            />
          );
        }
      )}
      <Divider />
    </div>
  );
};
