import React, { useState } from "react";
import { ShowMore } from "../ShowMore";
import { useTranslation } from "react-i18next";
import { Divider } from "../../Divider";
import {
  HopRoute,
  HopRouteStop,
  HopRoutePoint,
} from "../../../pages/ProductPage/interfaces";
import classes from "./HopOnOff.module.scss";

type HopOnOffItemProps = {
  dictionary: any;
  route: HopRoute;
  routePoints: HopRoutePoint[];
  routeStops: HopRouteStop[];
};

export const HopOnOffItem: React.FC<HopOnOffItemProps> = ({
  route,
  routePoints,
  routeStops,
}: HopOnOffItemProps) => {
  const { t } = useTranslation();
  let stops = route.stops.filter(
    (stop: HopRouteStop) => stop.stop_location_name
  );
  let points = route.point_of_interest.filter((item: HopRoutePoint) => {
    return item.location_name;
  });
  let stopLength = stops.length;
  let pointLength = points.length;

  const [hidden, setHidden] = useState(true);
  if (hidden) {
    stops = stops.slice(0, 2);
  }
  const [pointHidden, setPointHidden] = useState(true);
  if (pointHidden) {
    points = points.slice(0, 2);
  }
  return (
    <div>
      <span className={classes.label}>{route.name}</span>
      <div>
        <p>
          {routeStops.length} {t("t:stops")}
        </p>
        <p>
          {routePoints.length} {t("t:points_of_interest")}
        </p>
      </div>
      <div className={classes.scheduleContainer}>
        <p className={classes.schedule}>{t("t:operating_schedule")}:</p>
        <p className={classes.info}>{route.operating_schedule}</p>
      </div>
      <ul className={classes.steps}>
        {stops.map((step: HopRouteStop, index: number) => {
          return hidden && index > 1 ? null : (
            <div key={step.stop_location_code}>
              <li className={classes.stepItem}>
                <span
                  className={classes.stepConnector}
                  style={{
                    visibility: index === stopLength - 1 ? "hidden" : "visible",
                  }}
                />
                <p className={classes.stepNumber}>{index + 1}</p>
                <div className={classes.stepInfo}>
                  <p className={classes.stepLabel}>{step.stop_location_name}</p>
                </div>
              </li>
              <br />
            </div>
          );
        })}
      </ul>
      {stopLength > 2 ? (
        <ShowMore
          isCollapsed={hidden}
          onClick={() => {
            setHidden(!hidden);
          }}
        />
      ) : null}

      <div>
        <p className={classes.multiTitle}>
          {t("t:points_of_interest_passed_on")}
        </p>
        <div className={classes.steps}>
          {points.map((point: HopRoutePoint, index: number) => {
            return pointHidden && index > 1 ? null : (
              <div key={point.location_code}>
                <li className={classes.stepItem}>
                  <span
                    className={classes.pointConnector}
                    style={{
                      visibility:
                        index === pointLength - 1 ? "hidden" : "visible",
                    }}
                  />
                  <p className={classes.pointNumber} />
                  <div className={classes.stepInfo}>
                    <p className={classes.stepLabel}>{point.location_name}</p>
                  </div>
                </li>
                <br />
              </div>
            );
          })}
        </div>
      </div>
      {pointLength > 2 ? (
        <div className={classes.extraMargin}>
          <ShowMore
            isCollapsed={pointHidden}
            onClick={() => setPointHidden(!pointHidden)}
          />
        </div>
      ) : null}
      <Divider />
    </div>
  );
};
