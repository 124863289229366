import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import {
  defaultSiteSettings,
  SiteContext,
  SiteSettings,
} from "./store/context/site-context";
import { useTranslation } from "react-i18next";
import { ProductPage } from "./pages/ProductPage";
import externalParamsService, {
  ExternalParams,
} from "./services/external-params.service";
import { UrlUtils } from "./utils/urlUtils";
import "./translations/i18n";
import "./index.scss";

const App: React.FC = () => {
  const [externalToken, setExternalToken] = useState<string>("");
  const [siteSettings, setSiteSettings] =
  useState<SiteSettings>(defaultSiteSettings);
  const ref = useRef<HTMLDivElement>(null);
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  const fetchTicketData = async (ticketId: string) => {
    const pdfUrl = `${process.env.REACT_APP_API_BASE_URL}/ticket/${ticketId}`;
    const response = await axios(pdfUrl);
    if (response.data.startsWith('http')) {
      window.location.href = response.data;
    } else {
      window.location.href = pdfUrl;
    }
  };

  useEffect(() => {
    const token = UrlUtils.getQueryParam(ExternalParams.UserToken);
    if (token) {
      const externalToken = UrlUtils.getQueryParam(ExternalParams.UserToken);
      const savedMemberToken = externalParamsService.getMemberToken();
      if (savedMemberToken) {
        setExternalToken(savedMemberToken);
      } else if (externalToken) {
        externalParamsService.setMemberToken(externalToken);
        setExternalToken(externalToken);
      }
       return;
    }

      const urlPath = window.location.pathname.substring(1);
      console.log(urlPath); 
      if (urlPath) {
        fetchTicketData(urlPath);
        return;
     }

  }, []);

  return (
    <div className="App" ref={ref}>
      <div className="container">
        <SiteContext.Provider value={siteSettings}>
          <ProductPage token={externalToken} />
        </SiteContext.Provider>
      </div>
    </div>
  );
};

export default App;

