import React, { useState } from "react";
import { ShowMore } from "../ShowMore";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { IncludedExcluded } from "../../../pages/ProductPage/interfaces";
import classes from "./ProductList.module.scss";

type ProductListProps = {
  productData: any;
};

export const ProductList: React.FC<ProductListProps> = ({
  productData,
}: ProductListProps) => {
  const [hidden, setHidden] = useState(true);
  let included: IncludedExcluded[] = [];
  if (
    productData.hasOwnProperty("included_excluded") &&
    Array.isArray(productData.included_excluded) &&
    productData?.included_excluded.length > 0
  ) {
    included = productData?.included_excluded.filter(
      (item: any) => item.type === "INCLUDED"
    );
  }
  let excluded: IncludedExcluded[] = [];
  if (
    productData.hasOwnProperty("included_excluded") &&
    Array.isArray(productData.included_excluded) &&
    productData?.included_excluded.length > 0
  ) {
    excluded = productData?.included_excluded.filter(
      (item: any) => item.type === "EXCLUDED"
    );
  }

  let includedLength = included.length;
  let excludedLength = excluded.length;
  if (hidden) {
    included = included.slice(0, 2);
    excluded = excluded.slice(0, 2);
  }

  return (
    <div>
      <div className={classes.lists}>
        <ul className={classes.list}>
          {included.map((item: any, index: number) => (
            <div key={index} className={classes.listItem}>
              <CheckIcon color="action" fontSize="small" />
              <li className={classes.listItemAccept}>{item.line_text}</li>
            </div>
          ))}
        </ul>
        <ul className={classes.list}>
          {excluded.map((item: any, index: number) => (
            <div key={index} className={classes.listItem}>
              <CloseIcon color="action" fontSize="small" />
              <li className={classes.listItemDecline}>{item.line_text}</li>
            </div>
          ))}
        </ul>
      </div>
      {includedLength > 2 || excludedLength > 2 ? (
        <ShowMore
          isCollapsed={hidden}
          onClick={() => {
            setHidden(!hidden);
          }}
        />
      ) : null}
    </div>
  );
};
