import React from "react";

export type SiteSettings = {
  didLoadSettings: boolean;
  dictionary: any;
  resellerId: number | null;
  languageCode: string;
  isRtl: boolean;
  weekendStartDay: number | null;
  weekendEndDay: number | null;
  destinationsTitle: string | null;
  searchSettings: SearchSettings | null;
  productSettings: ProductSettings | null;
  paymentFlowSettings: PaymentFlowSettings | null
}

export type SearchSettings = {
  useSearchBar: boolean;
  title: string;
  destinationHint: string;
  calendarHint: string;
  buttonLabel: string;
  searchImage: string;
  defaultCoverImage: string;
}

export type ProductSettings = {
  purchaseButtonLabel: string;
  priceText: string;
  dateAndPartyTypeText: string;
  displayDiscountPercent: string;
  displayOriginalAmount: string;
  displayOrderAmount: string;
  whatToExpectTitle: string;
}

export type PaymentFlowSettings = {
  whatsAppServiceNumber: string;
  successImageUrl: string;
  failureImageUrl: string;
}

export const defaultSiteSettings: SiteSettings = {
  didLoadSettings: false,
  dictionary: require('../../translations/hebrew.json'),
  resellerId: null,
  languageCode: 'he',
  isRtl: true,
  destinationsTitle: null,
  weekendEndDay: null,
  weekendStartDay: null,
  searchSettings: null,
  productSettings: null,
  paymentFlowSettings: null
};

export const SiteContext = React.createContext<SiteSettings | null>(defaultSiteSettings)