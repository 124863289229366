import {storageService} from './storage.service';

export enum ExternalParams {
  AppId = "app",
  LanguageId = "language_id",
  UserToken = "token",
  GoogleTrackingId = "ga",
  TicketId = "ticket"
}

const setMemberToken = (token: string) => storageService.setExternalMemberToken(token);
const getMemberToken = () => storageService.getExternalMemberToken();

const setAppId = (appId: string) => storageService.setAppId(appId);
const getAppId = () => storageService.getAppId();

const setLanguageId = (languageId: string) => storageService.setLanguageId(languageId);
const getLanguageId = () => storageService.getLanguageId();

const externalParamsService = {
  setMemberToken,
  getMemberToken,
  setAppId,
  getAppId,
  setLanguageId,
  getLanguageId
}

export default externalParamsService;