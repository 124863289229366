import React, { useState, useContext } from "react";
import { SiteContext } from "../../../store/context/site-context";
import classes from "./ReadMore.module.scss";

type ReadMoreProps = {
  children: React.ReactNode;
};

export const ReadMoreComponent: React.FC<ReadMoreProps> = ({
  children,
}: ReadMoreProps) => {
  const text = children;
  const siteSettings = useContext(SiteContext);
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className={classes.text}>
      {isReadMore && text && typeof text === "string"
        ? text.slice(0, 150)
        : text}
      <span onClick={toggleReadMore} className={classes.readOrHide}>
        {isReadMore
          ? siteSettings?.dictionary?.read_more
          : siteSettings?.dictionary?.read_less}
      </span>
    </p>
  );
};
