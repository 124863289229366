import React from "react";
import { useTranslation } from "react-i18next";
import { ReadMoreComponent } from "../ReadMore";
import { Divider } from "../../Divider";
import classes from "./Activity.module.scss";

type ActivityProps = {
  siteSettings: any;
  productData: any;
};

export const Activity: React.FC<ActivityProps> = ({
  siteSettings,
  productData,
}: ActivityProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <p className={classes.title}>{t("t:sample_menu")}</p>
        <ul className={classes.list}>
          {productData?.itinerary?.meals?.map((meal: any) => {
            if (meal?.dish_name) {
              return (
                <li key={meal.dish_name} className={classes.item}>
                  <p className={classes.name}>{meal?.dish_name}</p>
                  {meal.dish_description.split("\n").map((descr: any) => {
                    return (
                      <p key={descr} className={classes.description}>
                        {descr}
                      </p>
                    );
                  })}
                </li>
              );
            }
          })}
        </ul>
      </div>

      <div className={classes.expect}>
        <p className={classes.title}>
          {/* {siteSettings.productSettings.whatToExpectTitle} */}
        </p>
        <div className={classes.description}>
          <ReadMoreComponent>
            {productData?.itinerary?.description}
          </ReadMoreComponent>
        </div>
      </div>
      <Divider />
    </div>
  );
};
