enum StorageKeys {
  RefreshToken = "refresh_token",
  MemberToken = "member_token",
  ExternalMemberToken = "external_member_token",
  AppId = "app_id",
  LanguageId = "language_id",
  Settings = "settings",
  Destination = "destination",
  Destinations = "destinations",
  DestinationId = "destination_id",
  ProductId = "product_id",
  ProductData = "product_data",
  ProductCategory = "product_category",
  BookingInfo = "booking_info",
  ProductLocations = "product_locations",
  RoutingStack = "routing_stack",
}

// Refresh token
function getRefreshToken() {
  return getFromLocalStorage(StorageKeys.RefreshToken);
}

function setRefreshToken(value: string) {
  return setToLocalStorage(StorageKeys.RefreshToken, value);
}

// Member token
function getMemberToken() {
  return getFromLocalStorage(StorageKeys.MemberToken);
}

function setMemberToken(value: string) {
  return setToLocalStorage(StorageKeys.MemberToken, value);
}

function getExternalMemberToken() {
  return getFromLocalStorage(StorageKeys.ExternalMemberToken);
}

function setExternalMemberToken(value: string) {
  return setToLocalStorage(StorageKeys.ExternalMemberToken, value);
}

// App id
function getAppId() {
  return getFromLocalStorage(StorageKeys.AppId);
}

function setAppId(value: string) {
  setToLocalStorage(StorageKeys.AppId, value);
}

// Language id
function getLanguageId() {
  return getFromLocalStorage(StorageKeys.LanguageId);
}

function setLanguageId(value: string) {
  setToLocalStorage(StorageKeys.LanguageId, value);
}

// Settings
function getSettings() {
  return getFromLocalStorage(StorageKeys.Settings);
}

function setSettings(value: string) {
  setToLocalStorage(StorageKeys.Settings, value);
}

// Destination
function getDestinations() {
  return getFromLocalStorage(StorageKeys.Destinations);
}

function setDestinations(value: string) {
  setToLocalStorage(StorageKeys.Destinations, value);
}

function getSelectedDestination() {
  return getFromLocalStorage(StorageKeys.Destination);
}

function setSelectedDestination(value: string) {
  removeFromLocalStorage(StorageKeys.DestinationId);
  setToLocalStorage(StorageKeys.Destination, value);
}

function getSelectedDestinationId() {
  return getFromLocalStorage(StorageKeys.DestinationId);
}

function setSelectedDestinationId(value: string) {
  removeFromLocalStorage(StorageKeys.Destination);
  setToLocalStorage(StorageKeys.DestinationId, value);
}

// Product
function getSelectedProductId() {
  return getFromLocalStorage(StorageKeys.ProductId);
}

function setSelectedProductId(value: string) {
  setToLocalStorage(StorageKeys.ProductId, value);
}

function getSelectedProductData() {
  return getFromLocalStorage(StorageKeys.ProductData);
}

function setSelectedProductData(value: string) {
  setToLocalStorage(StorageKeys.ProductData, value);
}

function getSelectedProductCategory() {
  return getFromLocalStorage(StorageKeys.ProductCategory);
}

function setSelectedProductCategory(value: string) {
  setToLocalStorage(StorageKeys.ProductCategory, value);
}

function getSelectedProductLocations() {
  return getFromLocalStorage(StorageKeys.ProductLocations);
}

function setSelectedProductLocations(value: string) {
  setToLocalStorage(StorageKeys.ProductLocations, value);
}

// Booking
function getBookingInfo() {
  return getFromLocalStorage(StorageKeys.BookingInfo);
}

function setBookingInfo(value: string) {
  setToLocalStorage(StorageKeys.BookingInfo, value);
}

// Routing
function getRoutingStack() {
  return getFromLocalStorage(StorageKeys.RoutingStack) ?? [];
}

function setRoutingStack(value: any) {
  setToLocalStorage(StorageKeys.RoutingStack, value);
}

// Utils
function getFromLocalStorage(path: string) {
  try {
    return JSON.parse(sessionStorage.getItem(path) || "");
  } catch (e) {
    return null;
  }
}

function removeFromLocalStorage(path: string) {
  sessionStorage.removeItem(path);
  return true;
}

function setToLocalStorage(path: string, value: any) {
  return sessionStorage.setItem(path, JSON.stringify(value));
}

const clearAll = () => sessionStorage.clear();

export const storageService = {
  getRefreshToken,
  setRefreshToken,
  setToLocalStorage,
  removeFromLocalStorage,
  getMemberToken,
  setMemberToken,
  setExternalMemberToken,
  getExternalMemberToken,
  setAppId,
  getAppId,
  setLanguageId,
  getLanguageId,
  getSelectedDestination,
  setSelectedDestination,
  getDestinations,
  setDestinations,
  getSelectedDestinationId,
  setSelectedDestinationId,
  getSelectedProductId,
  setSelectedProductId,
  getSelectedProductData,
  setSelectedProductData,
  getSelectedProductCategory,
  setSelectedProductCategory,
  getSettings,
  setSettings,
  getBookingInfo,
  setBookingInfo,
  getSelectedProductLocations,
  setSelectedProductLocations,
  getRoutingStack,
  setRoutingStack,
  clearAll,
};
