import React from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "../../Divider";
import classes from "./MeetingAndPickup.module.scss";

export const MeetingAndPickup: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Divider />
      <p className={classes.productTitle}>{t("t:meeting_and_pickup")}</p>
    </>
  );
};
