import React from "react";
import { HopOnOffItem } from "./HopOnOffItem";
import { HopRoute } from "../../../pages/ProductPage/interfaces";
import "./HopOnOff.module.scss";

type HopOnOffProps = {
  siteSettings: any;
  productData: any;
};

export const HopOnOff: React.FC<HopOnOffProps> = ({
  siteSettings,
  productData,
}: HopOnOffProps) => {
  return (
    <div>
      {/* <p className="multi__title">
        {siteSettings.productSettings.whatToExpectTitle}
      </p> */}
      <div>
        {productData?.itinerary?.map((item: HopRoute, index: number) => {
          return (
            <HopOnOffItem
              key={index}
              route={item}
              routePoints={item.point_of_interest}
              routeStops={item.stops}
              dictionary={siteSettings.dictionary}
            />
          );
        })}
      </div>
    </div>
  );
};
