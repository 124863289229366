import React, { useState } from "react";
import { ShowMore } from "../ShowMore";
import { Divider } from "../../Divider";
import { StandardRoute } from "../../../pages/ProductPage/interfaces";
import classes from "./ProductStepper.module.scss";

type ProductStepperProps = {
  steps: any;
};

export const ProductStepper: React.FC<ProductStepperProps> = ({
  steps,
}: ProductStepperProps) => {
  let length = steps?.length;
  let updatedSteps = steps;
  const [hidden, setHidden] = useState(true);
  if (hidden) {
    updatedSteps = updatedSteps.slice(0, 2);
  }

  return (
    <div className={classes.stepper}>
      <ul className={classes.steps}>
        {updatedSteps.map((step: StandardRoute, index: number) => {
          return hidden && index > 1 ? null : (
            <div key={step.location_code}>
              <li className={classes.stepItem}>
                <span
                  className={classes.stepConnector}
                  style={{
                    visibility: index === length - 1 ? "hidden" : "visible",
                  }}
                />
                <p className={classes.stepNumber}>{index + 1}</p>
                <div className={classes.stepInfo}>
                  <p className={classes.stepLabel}>{step.location_name}</p>
                  <p className={classes.stepDescription}>{step.description}</p>
                  {step.duration && (
                    <p className={classes.stepDuration}>{step.duration}</p>
                  )}
                </div>
              </li>
              <br />
            </div>
          );
        })}
      </ul>
      {length > 2 ? (
        <ShowMore
          isCollapsed={hidden}
          onClick={() => {
            setHidden(!hidden);
          }}
        />
      ) : null}

      <Divider />
    </div>
  );
};
