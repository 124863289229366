import React, { useState } from "react";
import { ShowMore } from "../ShowMore/ShowMore";
import { useTranslation } from "react-i18next";
import { MultiDayItineraryItem } from "../../../pages/ProductPage/interfaces";
import { ExpandMore } from "@mui/icons-material";
import classes from "./MultiDay.module.scss";

type MultiDayItemProps = {
  day: MultiDayItineraryItem;
  dictionary: any;
};

export const MultiDayItem: React.FC<MultiDayItemProps> = ({
  day,
  dictionary,
}: MultiDayItemProps) => {
  let length = day.items.length;
  let items = day.items;
  const { t } = useTranslation();

  const [hidden, setHidden] = useState(true);
  const [active, setActive] = useState(false);
  return (
    <div className={classes.multiItem}>
      <div
        onClick={() => {
          setActive(!active);
        }}
        className={classes.multiHeader}
      >
        <span className={classes.dayLabel}>
          {day.day_number} {t("t:day")}
        </span>
        <div className={classes.multiItemContainer}>
          <p className={classes.multiItemTitle}>{day.title}</p>
          <ExpandMore
            style={{
              margin: "0 30px",
              transform: active ? "rotate(180deg)" : "",
            }}
          />
        </div>

        <p className={classes.multiItemCount}>
          {day.items.length} {t("t:stops")}
        </p>
      </div>
      <div className={!active ? classes.nonActive : ""}>
        <ul className={classes.steps}>
          {items.map((step, index) => {
            return hidden && index > 1 ? null : (
              <div key={step.location_code}>
                <li className={classes.stepItem}>
                  <span
                    className={classes.stepConnector}
                    style={{
                      visibility: index === length - 1 ? "hidden" : "visible",
                    }}
                  />
                  <p className={classes.stepNumber}>{index + 1}</p>
                  <div className={classes.stepInfo}>
                    <p className={classes.stepLabel}>{step.location_name}</p>
                    <p className={classes.stepDescription}>
                      {step.description}
                    </p>
                    <p className={classes.stepDuration}>{step.duration}</p>
                  </div>
                </li>
                <br />
              </div>
            );
          })}
        </ul>
        {length > 2 ? (
          <ShowMore
            isCollapsed={hidden}
            onClick={() => {
              setHidden(!hidden);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};
