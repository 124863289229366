import React, { MouseEventHandler, useContext } from "react";
import { SiteContext } from "../../../store/context/site-context";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import classes from "./ShowMore.module.scss";

type ShowMoreProps = {
  isCollapsed: boolean;
  onClick: MouseEventHandler;
};

export const ShowMore: React.FC<ShowMoreProps> = ({
  isCollapsed,
  onClick,
}: ShowMoreProps) => {
  //   const siteSettings = useContext(SiteContext);
  //   const dictionary = siteSettings.dictionary;
  const { t } = useTranslation();

  return (
    <div className={classes.showMoreContainer}>
      <span onClick={onClick} className={classes.showMore}>
        {t(`t:${isCollapsed ? "show_more" : "show_less"}`)}
      </span>
      <ExpandMore
        className={classes.showMoreArrow}
        style={{ transform: isCollapsed ? "" : "rotate(180deg)" }}
      />
    </div>
  );
};
