import React from "react";
import {
  UnstructuredRoute,
  UnstructuredRoutePoint,
} from "../../../pages/ProductPage/interfaces";
import { Divider } from "../../Divider";
import classes from "./Unstructured.module.scss";

type UnstructuredProps = {
  siteSettings: any;
  productData: any;
};

export const Unstructured: React.FC<UnstructuredProps> = ({
  siteSettings,
  productData,
}: UnstructuredProps) => {
  return (
    <div>
      <p className={classes.multiTitle}>
        {/* {siteSettings.productSettings.whatToExpectTitle} */}
      </p>
      <div>
        <p className={classes.description}>
          {productData?.itinerary_description}
        </p>
        {productData?.point_of_interest?.map(
          (point: UnstructuredRoutePoint, index: number) => {
            return (
              <div key={index}>
                <p className={classes.title}>{point?.location_name}</p>
                <p className={classes.description}>
                  {productData?.description}
                </p>
              </div>
            );
          }
        )}
      </div>
      <Divider />
    </div>
  );
};
