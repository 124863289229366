import axios from "axios";
import React, { useRef, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from '@mui/material';
import { SiteContext } from "../../store/context/site-context";
import { ProductList } from "../../components/TicketDetails/ProductList";
import { ProductStepper } from "../../components/TicketDetails/ProductStepper";
import { Activity } from "../../components/TicketDetails/Activity";
import { MultiDay } from "../../components/TicketDetails/MultiDay";
import { HopOnOff } from "../../components/TicketDetails/HopOnOff";
import { Unstructured } from "../../components/TicketDetails/Unstructured";
import { AdditionalInfo } from "../../components/TicketDetails/AdditionalInfo";
import { MeetingAndPickup } from "../../components/TicketDetails/MeetingAndPickup";
import { Redemption } from "../../components/TicketDetails/Redemption";
import { LineBreakingParagraph } from "../../components/TicketDetails/LineBreakingParagraph";
import { Divider } from "../../components/Divider";
import classes from "./ProductPage.module.scss";

type ProductPageProps = {
  token: string;
};

export const ProductPage: React.FC<ProductPageProps> = ({ token }) => {
  const [productData, setProductData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const siteSettings = useContext(SiteContext);

  useEffect(() => {
    async function fetchTicketData() {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axios(`${process.env.REACT_APP_API_BASE_URL}/${token}`);
        setProductData(response.data);
      } catch (error) {
        setError("Failed to fetch ticket details!");
      } finally {
        setIsLoading(false);
      }
    }

    fetchTicketData();
  }, [token]);

  if (isLoading) {
    return <div className={classes.notifications}><CircularProgress size={50} /></div>;
  }

  if (!isLoading && error) {
    return <div className={classes.notifications}>{error}</div>;
  }

  return (
    <div className={classes.productPage} ref={ref}>
      {productData.status_code === 0 && (
        <div className={classes.productDetails}>
          <div>
            <p className={classes.productTitle}>{productData?.title || ""}</p>
          </div>
          <Divider />
          <div>
            <p className={classes.productTitle}>{t("t:overview")}</p>
            <p className={classes.productText}>{productData?.description}</p>
          </div>
          <Divider />

          <div>
            <p className={classes.productTitle}>{t("t:whats_included")}</p>
            <ProductList productData={productData} />
          </div>
          <Divider />

          <div>
            {(() => {
              switch (productData?.itinerary_type_code) {
                case "STANDARD":
                  return (
                    <div>
                      <p className={classes.productTitle}>{t("t:itinerary")}</p>
                      <p className={classes.productText} />
                      <ProductStepper steps={productData?.itinerary} />
                    </div>
                  );
                case "ACTIVITY":
                  return (
                    <Activity
                      siteSettings={siteSettings}
                      productData={productData}
                    />
                  );
                case "MULTI_DAY_TOUR":
                  return (
                    <MultiDay
                      siteSettings={siteSettings}
                      productData={productData}
                    />
                  );
                case "HOP_ON_HOP_OFF":
                  return (
                    <HopOnOff
                      siteSettings={siteSettings}
                      productData={productData}
                    />
                  );
                case "UNSTRUCTURED":
                  return (
                    <Unstructured
                      siteSettings={siteSettings}
                      productData={productData}
                    />
                  );
              }
            })()}
          </div>

          {productData &&
            productData.hasOwnProperty("additional_info") &&
            Array.isArray(productData.additional_info) &&
            productData?.additional_info?.length > 0 && (
              <AdditionalInfo items={productData?.additional_info} />
            )}

          {productData?.start_locations?.length > 0 ||
            (productData?.end_locations?.length > 0 && <MeetingAndPickup />)}

          {productData.hasOwnProperty("redemption") && productData.redemption.hasOwnProperty("redemption_special_instructions") ?
            <div>
              <Divider />
              <p className={classes.productTitle}>{t("t:how_to_redeem_title")}</p>
              <p className={classes.productText}>
                {productData?.redemption?.redemption_special_instructions}
              </p>
            </div> : null
          }

          {/* {((productData?.redemption_locations?.length > 0) || (productData?.redemption_special_instructions)) ?
            <div>
              <Divider />
              <div>
                <p className={classes.productTitle}>{t("t:how_to_redeem_title")}</p>
                <LineBreakingParagraph text={productData?.redemption_special_instructions} className={classes.productText} />
                {(productData?.redemption_locations?.length > 0) ?
                  <Redemption productData={productData} /> : null
                }
              </div>
            </div> : null
          } */}

          <div>
            <Divider />
            <p className={classes.productTitle}>
              {t("t:cancellation_policy_title")}
            </p>
            <p className={classes.productText}>
              {productData?.cancellation_policy_description}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
