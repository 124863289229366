import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ShowMore } from "../ShowMore/ShowMore";
import classes from "./AdditionalInfo.module.scss";

type AdditionalInfoProps = {
  items: string[];
};

export const AdditionalInfo = ({ items }: AdditionalInfoProps) => {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(true);

  return (
    <>
      <p className={classes.title}>{t("t:additional_information")}</p>
      <ul>
        {items.map((item: string, index: number) => {
          return hidden && index > 1 ? null : (
            <li className={classes.item} key={index}>
              {item}
            </li>
          );
        })}
      </ul>

      {items.length > 2 ? (
        <ShowMore
          isCollapsed={hidden}
          onClick={() => {
            setHidden(!hidden);
          }}
        />
      ) : null}
    </>
  );
};
